<template>
    <div>
        <v-dialog v-model="display" scrollable persistent width="950">
            <v-card flat v-if="loading">
                <v-card-title></v-card-title>
                <v-card-text>
                    <div class="text-center" >
                    <v-progress-circular indeterminate></v-progress-circular>
                    </div>
                </v-card-text>
            </v-card>
            <v-card v-else flat>
                <v-card-title>{{ report.companyName }}
                    <v-spacer></v-spacer>
                    <v-tooltip v-if="showSelect.length" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                color="primary"
                                depressed
                                class="ma-2 white--text"
                                    @click="openPolice(showSelect[0].id)"
                                v-bind="attrs"
                                v-on="on">
                                {{ $t('View User') }}
                                <v-icon
                                    right
                                    dark>
                                        mdi-eye
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t('View User') }}</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-text>
                <v-subheader>{{ $t('details') }}</v-subheader>
                    <v-simple-table dense>
                        <tbody>
                            <tr>
                                <th>{{ $t("company_name") }}</th>
                                <td>{{ report.companyName }}</td>
                                <th>{{ $t("policies total") }}</th>
                                <td>{{ report.policies.length }}</td>
                                <th>{{ $t("Brokers share") }}</th>
                                <td>{{ report.companyRate }}</td>
                            </tr>
                            <tr>
                                <th>{{ $t("Stamp duty") }}</th>
                                <td>{{ getTotalFees(report.feesInfo, "Stamp duty") }}</td>
                                <th>{{ $t("Supervision fees") }}</th>
                                <td>{{ getTotalFees(report.feesInfo, "Supervision fees") }}</td>
                                <th>{{ $t("Policy fees") }}</th>
                                <td>{{ getTotalFees(report.feesInfo, "Policy fees") }}</td>
                            </tr>
                             <tr>
                                <th>{{ $t("total fees") }}</th>
                                <td>{{ report.fees}}</td>
                                <th>{{ $t("sub Total") }}</th>
                                <td>{{ report.subTotal }}</td>
                                <th>{{ $t("total") }}</th>
                                <td>{{ report.total }}</td>
                            </tr>
                        </tbody>
                    </v-simple-table>
                   
                    <v-subheader>{{ $t('policies') }}</v-subheader>
                    <v-data-table v-model="showSelect" show-select single-select dense :headers="police" :items="report.policies"></v-data-table>
                </v-card-text>
                <v-card-actions>
                    <v-btn text @click="close">{{ $t("close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <show-police  v-if="showPolice.show" 
        :id="showPolice.id" 
        @close="showPolice.show = false"></show-police>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import showPolice from '../Policies/showPolice.vue';
export default {
  components: { showPolice },
    name: "showReport",
    props:['id'],
    data() {
        return {
            display: true,
            loading: false,
            report:{},
            showSelect: [],
            showPolice: {
                show: false,
                id: null
            },
             police: [
          {
            text: this.$t("policyNo"),
            sortable: false,
            value: "policyNo",
          },
          
          {
            text: this.$t("company_name"),
            sortable: false,
            value: "companyName",
          },
                    {
            text: this.$t("scheme"),
            sortable: false,
            value: "scheme.name",
          },
          
          {
            text: this.$t("startDate"),
            sortable: false,
            value: "startDate",
           
          },
          {
            text: this.$t("endDate"),
            sortable: false,
            value: "endDate",
           
          },
          {
            text: this.$t("paymentType"),
            sortable: false,
            value: "paymentType",
          },
           {
            text: this.$t("status"),
            sortable: false,
            value: "status",
          },
          {
            text: this.$t("total"),
            sortable: false,
            value: "total",
          },
          
        ],
        }
    },
    computed: {
    },
    methods: {
        ...mapActions("Api", ["getAll"]),
    showReport() {
      try {
        this.loading = true;
        this.getAll(`reports/companies/${this.id}/policies`)
          .then((res) => {
              this.report = res.data.data;
           
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } catch(e) {
        console.log(e);
         this.loading = false;
      }
    },
    getTotalFees(v, type) {   
        var price = 0 ;
        v.forEach(element => {
            if(element.name == type) {
                price  += Number(element.price);
            
            }
        });
        return price; 
        
    },
    close() {
      this.$emit("close");
    },
     openPolice(value) {
        this.showPolice.id = value;
        this.showPolice.show = true;
    }
        
    },
    created(){
        if(this.id != null) this.showReport();
     }
}
</script>

<style>

</style>