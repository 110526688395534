<template>
    <div>
        <v-card flat>
            <v-card-title>
            {{ $t('schemes reports') }}
            <v-spacer></v-spacer>
            </v-card-title>
            <v-card-text>
                <v-data-table v-model="select" show-select single-select :loading="loading" dense :headers="headers.companyReport" :items="reports">
                    <template v-slot:item.Stampduty="{ item }">
                        {{ getTotalFees(item.feesInfo, "Stamp duty") }}
                    </template>
                    <template v-slot:item.Supervisionfees="{ item }">
                        {{ getTotalFees(item.feesInfo, "Supervision fees") }}
                    </template>
                     <template v-slot:item.Policyfees="{ item }">
                        {{ getTotalFees(item.feesInfo, "Policy fees") }}
                    </template>
                </v-data-table>
            </v-card-text>
        </v-card>
        <show-report v-if="showReport.show" :id="showReport.id"  @close="showReport.show = false"></show-report>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import showReport from './showReport.vue';
export default {
  components: { showReport },
    name: "SchemesReports",
    data() {
        return {
            loading: false,
            select:[],
            reports:[],
            showReport: {
                id: null,
                show: false
            },
            headers: {
                companyReport:[
                    { text: this.$t("company_name"), sortable: false, value: "companyName" },
                    { text: this.$t("scheme"), sortable: false, value: "schemeName" },
                    { text: this.$t("policiesCount"), sortable: false, value: "policiesCount" },
                    { text: this.$t("Stamp duty"), sortable: false, value: "Stampduty" },
                    { text: this.$t("Supervision fees"), sortable: false, value: "Supervisionfees" },
                    { text: this.$t("Policy fees"), sortable: false, value: "Policyfees" },
                    { text: this.$t("total fees"), sortable: false, value: "fees" },
                    { text: this.$t("sub Total"), sortable: false, value: "subTotal" },
                    { text: this.$t("total"), sortable: false, value: "total" },
                ]
            }
        }
    },
    computed: {
       
       
    },
    methods: {
        ...mapActions("Api", ["getAll"]),
    getAllReport() {
      try {
        this.loading = true;
        this.getAll(`reports/schemes`)
          .then((res) => {
              this.reports = res.data.data;
           
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      } catch(e) {
        console.log(e);
         this.loading = false;
      }
    },
     getTotalFees(v, type) {   
        var price = 0 ;
        v.forEach(element => {
            if(element.name == type) {
                price  += Number(element.price);
            
            }
        });
        return price; 
        
    },
        openReport(v) {
            console.log('tag', v);
            this.showReport.id = v;
            this.showReport.show = true;
        }
        
    },
    created(){
        this.getAllReport();
    }

}
</script>

<style>

</style>